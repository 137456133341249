/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'media_v1',
    'attributes': {
      'content_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'expected_media_category': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'media': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'draft_guess_v1': {
        'type': 'one-to-one'
      },
      'guess_v1': {
        'type': 'one-to-one'
      },
      'user_v1': {
        'type': 'one-to-one'
      },
      'user_v2': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'content_type': Empty,
    'expected_media_category': Empty,
    'media': Empty,
    'user': Empty,
    'guess': Empty,
    'updated_at': Empty,
    'draft_guess': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'content_type': '',
    'expected_media_category': '',
    'media': '',
    'id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'meta': new Map(),
    'user': new Map(),
    'guess': new Map(),
    'draft_guess': new Map(),
    'relationshipMeta': new Map()
  }
);

export class MediaModelV1 extends GenericModel(ModelRecord, ModelConfig, 'MediaModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyMediaModelV1Model = _emptyMediaModelV1Model ? _emptyMediaModelV1Model : new MediaModelV1(DefaultModel);
    return _emptyMediaModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'MediaModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'media_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getContentType() {
    const value = this.getValueForAttribute('content_type');
    return value;
  }

  /**
   * @type {string}
   */
  getExpectedMediaCategory() {
    const value = this.getValueForAttribute('expected_media_category');
    return value;
  }

  setExpectedMediaCategory(value) {
    return this.setField('expected_media_category', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getMedia() {
    const value = this.getValueForAttribute('media');
    return value;
  }

  setMedia(value) {
    return this.setField('media', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {draft_guess_v1 (one)}
   */
  getDraftGuess() {
    const value = this.getValueForAttribute('draft_guess');
    return value;
  }

  // -- Relationship Fields: draft_guess --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guess_v1 (one)}
   */
  getGuess() {
    const value = this.getValueForAttribute('guess');
    return value;
  }

  // -- Relationship Fields: guess --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_v2 (one)}
   */
  getUser() {
    const value = this.getValueForAttribute('user');
    return value;
  }

  // -- Relationship Fields: user --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyMediaModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'content_type': null,
  'expected_media_category': null,
  'id': null,
  'inserted_at': null,
  'media': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'content_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix']),
  'draft_guess_v1': new Set(['associated']),
  'expected_media_category': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix']),
  'guess_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'media': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_v1': new Set(['associated']),
  'user_v2': new Set(['associated']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Media = MediaModelV1;
