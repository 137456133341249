/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_subjects_v1',
    'attributes': {
      'featured_as': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'domain': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'translate_supplements': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'hidden': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'banner': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'should_show_label_table': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'code': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'published': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'alternate_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'footer_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free_response_questions_published': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'subscriptions_prices': {
        'type': 'map[]',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'logo_image_url': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'translate_questions': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'group': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'guides_v1': {
        'type': 'one-to-many'
      },
      'subscriptions_v1': {
        'type': 'one-to-many'
      },
      'exams_v1': {
        'type': 'one-to-many'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'curriculum_area_v1': {
        'type': 'one-to-one'
      },
      'subject_faqs_v1': {
        'type': 'one-to-many'
      },
      'school_license_subject_groups_v1': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'authors_v1': {
        'type': 'one-to-many'
      },
      'authors_v2': {
        'type': 'one-to-many'
      },
      'authoring_labels_v1': {
        'type': 'one-to-many'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'subject_author_permissions_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      },
      'labels_v1': {
        'type': 'one-to-many'
      },
      'authoring_guides_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v1': {
        'type': 'one-to-many'
      },
      'school_licenses_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'authoring_question_sets': Empty,
    'subject_author_permissions': Empty,
    'featured_as': Empty,
    'domain': Empty,
    'student_classrooms': Empty,
    'authoring_labels': Empty,
    'authors': Empty,
    'translate_supplements': Empty,
    'question_sets': Empty,
    'hidden': Empty,
    'name': Empty,
    'authoring_exams': Empty,
    'banner': Empty,
    'should_show_label_table': Empty,
    'code': Empty,
    'published': Empty,
    'school_license_subject_groups': Empty,
    'alternate_name': Empty,
    'subject_faqs': Empty,
    'footer_text': Empty,
    'free_response_questions_published': Empty,
    'curriculum_area': Empty,
    'guide_levels': Empty,
    'authoring_supplements': Empty,
    'subscriptions_prices': Empty,
    'exams': Empty,
    'subscriptions': Empty,
    'guides': Empty,
    'updated_at': Empty,
    'logo_image_url': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'supplements': Empty,
    'id': Empty,
    'url_slug': Empty,
    'translate_questions': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'school_licenses': Empty,
    'group': Empty,
    'search_standards': Empty,
    'authoring_guides': Empty,
    'labels': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'count_of_rejected_questions': Empty,
    'count_of_published_questions': Empty,
    'count_of_archived_questions': Empty,
    'count_of_pending_questions': Empty,
    'count_of_hidden_questions': Empty,
    'count_of_questions': Empty,
    'count_of_draft_questions': Empty,
    'authoring_question_set_authors': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'featured_as': '',
    'domain': '',
    'name': '',
    'banner': '',
    'code': '',
    'alternate_name': '',
    'footer_text': '',
    'logo_image_url': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'group': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'authoring_question_sets': new List(),
    'subject_author_permissions': new List(),
    'student_classrooms': new List(),
    'authoring_labels': new List(),
    'authors': new List(),
    'question_sets': new List(),
    'authoring_exams': new List(),
    'labels': new List(),
    'meta': new Map(),
    'translate_supplements': false,
    'hidden': false,
    'should_show_label_table': false,
    'published': false,
    'school_license_subject_groups': new List(),
    'subject_faqs': new List(),
    'free_response_questions_published': false,
    'guide_levels': new List(),
    'authoring_supplements': new List(),
    'subscriptions_prices': new List(),
    'exams': new List(),
    'subscriptions': new List(),
    'guides': new List(),
    'authoring_guide_levels': new List(),
    'supplements': new List(),
    'authoring_guides': new List(),
    'curriculum_area': new Map(),
    'translate_questions': false,
    'school_licenses': new List(),
    'search_standards': new List(),
    'relationshipMeta': new Map()
  }
);

export class AuthoringSubjectModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringSubjectModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringSubjectModelV1Model = _emptyAuthoringSubjectModelV1Model ? _emptyAuthoringSubjectModelV1Model : new AuthoringSubjectModelV1(DefaultModel);
    return _emptyAuthoringSubjectModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringSubjectModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_subject_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getAlternateName() {
    const value = this.getValueForAttribute('alternate_name');
    return value;
  }

  setAlternateName(value) {
    return this.setField('alternate_name', value);
  }

  /**
   * @type {string}
   */
  getBanner() {
    const value = this.getValueForAttribute('banner');
    return value;
  }

  setBanner(value) {
    return this.setField('banner', value);
  }

  /**
   * @type {string}
   */
  getCode() {
    const value = this.getValueForAttribute('code');
    return value;
  }

  setCode(value) {
    return this.setField('code', value);
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {string}
   */
  getDomain() {
    const value = this.getValueForAttribute('domain');
    return value;
  }

  setDomain(value) {
    return this.setField('domain', value);
  }

  /**
   * @type {string}
   */
  getFeaturedAs() {
    const value = this.getValueForAttribute('featured_as');
    return value;
  }

  setFeaturedAs(value) {
    return this.setField('featured_as', value);
  }

  /**
   * @type {string}
   */
  getFooterText() {
    const value = this.getValueForAttribute('footer_text');
    return value;
  }

  setFooterText(value) {
    return this.setField('footer_text', value);
  }

  /**
   * @type {boolean}
   */
  isFreeResponseQuestionsPublished() {
    const value = this.getValueForAttribute('free_response_questions_published');
    return value;
  }

  setFreeResponseQuestionsPublished(value) {
    return this.setField('free_response_questions_published', value);
  }

  /**
   * @type {string}
   */
  getGroup() {
    const value = this.getValueForAttribute('group');
    return value;
  }

  setGroup(value) {
    return this.setField('group', value);
  }

  /**
   * @type {boolean}
   */
  isHidden() {
    const value = this.getValueForAttribute('hidden');
    return value;
  }

  setHidden(value) {
    return this.setField('hidden', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLogoImageUrl() {
    const value = this.getValueForAttribute('logo_image_url');
    return value;
  }

  setLogoImageUrl(value) {
    return this.setField('logo_image_url', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {boolean}
   */
  isPublished() {
    const value = this.getValueForAttribute('published');
    return value;
  }

  setPublished(value) {
    return this.setField('published', value);
  }

  /**
   * @type {boolean}
   */
  isShouldShowLabelTable() {
    const value = this.getValueForAttribute('should_show_label_table');
    return value;
  }

  setShouldShowLabelTable(value) {
    return this.setField('should_show_label_table', value);
  }

  /**
   * @type {map[]}
   */
  getSubscriptionsPrices() {
    const value = this.getValueForAttribute('subscriptions_prices');
    return value;
  }

  setSubscriptionsPrices(value) {
    return this.setField('subscriptions_prices', value);
  }

  /**
   * @type {boolean}
   */
  isTranslateQuestions() {
    const value = this.getValueForAttribute('translate_questions');
    return value;
  }

  setTranslateQuestions(value) {
    return this.setField('translate_questions', value);
  }

  /**
   * @type {boolean}
   */
  isTranslateSupplements() {
    const value = this.getValueForAttribute('translate_supplements');
    return value;
  }

  setTranslateSupplements(value) {
    return this.setField('translate_supplements', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAuthoringQuestionSetAuthors() {
        return this.getMeta().getAuthoringQuestionSetAuthors();
      }
    
      getCountOfArchivedQuestions() {
        return this.getMeta().getCountOfArchivedQuestions();
      }
    
      getCountOfDraftQuestions() {
        return this.getMeta().getCountOfDraftQuestions();
      }
    
      getCountOfHiddenQuestions() {
        return this.getMeta().getCountOfHiddenQuestions();
      }
    
      getCountOfPendingQuestions() {
        return this.getMeta().getCountOfPendingQuestions();
      }
    
      getCountOfPublishedQuestions() {
        return this.getMeta().getCountOfPublishedQuestions();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getCountOfRejectedQuestions() {
        return this.getMeta().getCountOfRejectedQuestions();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {map[]}
           */
          getAuthoringQuestionSetAuthors() {
            const value = meta.get('authoring_question_set_authors');
            validate(value, 'authoring_question_set_authors');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getCountOfArchivedQuestions() {
            const value = meta.get('count_of_archived_questions');
            validate(value, 'count_of_archived_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDraftQuestions() {
            const value = meta.get('count_of_draft_questions');
            validate(value, 'count_of_draft_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfHiddenQuestions() {
            const value = meta.get('count_of_hidden_questions');
            validate(value, 'count_of_hidden_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPendingQuestions() {
            const value = meta.get('count_of_pending_questions');
            validate(value, 'count_of_pending_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPublishedQuestions() {
            const value = meta.get('count_of_published_questions');
            validate(value, 'count_of_published_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfRejectedQuestions() {
            const value = meta.get('count_of_rejected_questions');
            validate(value, 'count_of_rejected_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withClassroomRelationshipGetters(rel));
    } else {
      return this.__withClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_author_permission_v1 (many)}
   */
  getSubjectAuthorPermissions() {
    const value = this.getValueForAttribute('subject_author_permissions');
    return value;
  }

  // -- Relationship Fields: subject_author_permissions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentClassroomRelationshipGetters(rel));
    } else {
      return this.__withStudentClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_label_v1 (many)}
   */
  getAuthoringLabels() {
    const value = this.getValueForAttribute('authoring_labels');
    return value;
  }

  // -- Relationship Fields: authoring_labels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_v2 (many)}
   */
  getAuthors() {
    const value = this.getValueForAttribute('authors');
    return value;
  }

  // -- Relationship Fields: authors --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withUserRelationshipGetters(data) {
    return {
      getCanEditGuideLevels() {
        return data.get('can_edit_guide_levels', null);
      },
      getCanEditLabels() {
        return data.get('can_edit_labels', null);
      },
      getCanEditSupplements() {
        return data.get('can_edit_supplements', null);
      },
      getCanEditQuestions() {
        return data.get('can_edit_questions', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getUserRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'user'], new Map());
    if (!id) {
      return data.map((rel) => this.__withUserRelationshipGetters(rel));
    } else {
      return this.__withUserRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_subject_group_v1 (many)}
   */
  getSchoolLicenseSubjectGroups() {
    const value = this.getValueForAttribute('school_license_subject_groups');
    return value;
  }

  // -- Relationship Fields: school_license_subject_groups --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_faq_v1 (many)}
   */
  getSubjectFaqs() {
    const value = this.getValueForAttribute('subject_faqs');
    return value;
  }

  // -- Relationship Fields: subject_faqs --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {curriculum_area_v1 (one)}
   */
  getCurriculumArea() {
    const value = this.getValueForAttribute('curriculum_area');
    return value;
  }

  // -- Relationship Fields: curriculum_area --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {exam_v1 (many)}
   */
  getExams() {
    const value = this.getValueForAttribute('exams');
    return value;
  }

  // -- Relationship Fields: exams --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subscription_v1 (many)}
   */
  getSubscriptions() {
    const value = this.getValueForAttribute('subscriptions');
    return value;
  }

  // -- Relationship Fields: subscriptions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_v1 (many)}
   */
  getGuides() {
    const value = this.getValueForAttribute('guides');
    return value;
  }

  // -- Relationship Fields: guides --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_v1 (many)}
   */
  getSchoolLicenses() {
    const value = this.getValueForAttribute('school_licenses');
    return value;
  }

  // -- Relationship Fields: school_licenses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_standard_v1 (many)}
   */
  getSearchStandards() {
    const value = this.getValueForAttribute('search_standards');
    return value;
  }

  // -- Relationship Fields: search_standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_v1 (many)}
   */
  getAuthoringGuides() {
    const value = this.getValueForAttribute('authoring_guides');
    return value;
  }

  // -- Relationship Fields: authoring_guides --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {label_v1 (many)}
   */
  getLabels() {
    const value = this.getValueForAttribute('labels');
    return value;
  }

  // -- Relationship Fields: labels --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringSubjectModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'alternate_name': null,
  'banner': null,
  'code': null,
  'description': null,
  'domain': null,
  'featured_as': null,
  'footer_text': null,
  'free_response_questions_published': null,
  'group': null,
  'hidden': null,
  'id': null,
  'inserted_at': null,
  'logo_image_url': null,
  'meta.count_of_archived_questions': null,
  'meta.count_of_draft_questions': null,
  'meta.count_of_hidden_questions': null,
  'meta.count_of_pending_questions': null,
  'meta.count_of_published_questions': null,
  'meta.count_of_questions': null,
  'meta.count_of_rejected_questions': null,
  'name': null,
  'published': null,
  'should_show_label_table': null,
  'translate_questions': null,
  'translate_supplements': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'alternate_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_guides_v1': new Set(['associated']),
  'authoring_labels_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'authors_v1': new Set(['associated']),
  'authors_v2': new Set(['associated']),
  'banner': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'classrooms_v1': new Set(['associated']),
  'code': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'curriculum_area_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'domain': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'exams_v1': new Set(['associated']),
  'featured_as': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'footer_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free_response_questions_published': new Set(['exact','not','null']),
  'group': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'guides_v1': new Set(['associated']),
  'hidden': new Set(['exact','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'labels_v1': new Set(['associated']),
  'logo_image_url': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.count_of_archived_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_draft_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_hidden_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_pending_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_published_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_rejected_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'published': new Set(['exact','not','null']),
  'question_sets_v1': new Set(['associated']),
  'school_license_subject_groups_v1': new Set(['associated']),
  'school_licenses_v1': new Set(['associated']),
  'search_standards_v1': new Set(['associated']),
  'should_show_label_table': new Set(['exact','not','null']),
  'student_classrooms_v1': new Set(['associated']),
  'subject_author_permissions_v1': new Set(['associated']),
  'subject_faqs_v1': new Set(['associated']),
  'subscriptions_prices': new Set(['null']),
  'subscriptions_v1': new Set(['associated']),
  'supplements_v1': new Set(['associated']),
  'translate_questions': new Set(['exact','not','null']),
  'translate_supplements': new Set(['exact','not','null']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'owner_id': 'meta.context.owner.id',
    'subject_counts': 'meta.context.subject.counts',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringSubject = AuthoringSubjectModelV1;
