/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'guide_levels_v1',
    'attributes': {
      'level': {
        'type': 'ltree',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'summary': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'position': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'theme_img': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'nlevel': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'tags_v1': {
        'type': 'one-to-many'
      },
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'subtopics_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'topics_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_question_sets': Empty,
    'topics': Empty,
    'authoring_subject': Empty,
    'subtopics': Empty,
    'level': Empty,
    'authoring_questions': Empty,
    'summary': Empty,
    'position': Empty,
    'question_sets': Empty,
    'name': Empty,
    'subject': Empty,
    'free': Empty,
    'theme_img': Empty,
    'questions': Empty,
    'search_tags': Empty,
    'search_questions': Empty,
    'nlevel': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'tags': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'url_slug': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'correctly_answered_in_guide': Empty,
    'populated_metadata_fields': Empty,
    'total_answered_in_guide': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'level': '',
    'summary': '',
    'name': '',
    'theme_img': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_question_sets': new List(),
    'topics': new List(),
    'subtopics': new List(),
    'authoring_questions': new List(),
    'question_sets': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'tags': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'position': 0,
    'subject': new Map(),
    'free': false,
    'questions': new List(),
    'search_tags': new List(),
    'nlevel': 0,
    'relationshipMeta': new Map()
  }
);

export class GuideLevelModelV1 extends GenericModel(ModelRecord, ModelConfig, 'GuideLevelModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyGuideLevelModelV1Model = _emptyGuideLevelModelV1Model ? _emptyGuideLevelModelV1Model : new GuideLevelModelV1(DefaultModel);
    return _emptyGuideLevelModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'GuideLevelModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'guide_level_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {boolean}
   */
  isFree() {
    const value = this.getValueForAttribute('free');
    return value;
  }

  setFree(value) {
    return this.setField('free', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {ltree}
   */
  getLevel() {
    const value = this.getValueForAttribute('level');
    return value;
  }

  setLevel(value) {
    return this.setField('level', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {integer}
   */
  getNlevel() {
    const value = this.getValueForAttribute('nlevel');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setNlevel(value) {
    return this.setField('nlevel', value);
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPosition(value) {
    return this.setField('position', value);
  }

  /**
   * @type {string}
   */
  getSummary() {
    const value = this.getValueForAttribute('summary');
    return value;
  }

  setSummary(value) {
    return this.setField('summary', value);
  }

  /**
   * @type {string}
   */
  getThemeImg() {
    const value = this.getValueForAttribute('theme_img');
    return value;
  }

  setThemeImg(value) {
    return this.setField('theme_img', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getCorrectlyAnsweredInGuide() {
        return this.getMeta().getCorrectlyAnsweredInGuide();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getTotalAnsweredInGuide() {
        return this.getMeta().getTotalAnsweredInGuide();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getCorrectlyAnsweredInGuide() {
            const value = meta.get('correctly_answered_in_guide');
            validate(value, 'correctly_answered_in_guide');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getTotalAnsweredInGuide() {
            const value = meta.get('total_answered_in_guide');
            validate(value, 'total_answered_in_guide');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {guide_level_v1 (many)}
   */
  getTopics() {
    const value = this.getValueForAttribute('topics');
    return value;
  }

  // -- Relationship Fields: topics --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v1 (many)}
   */
  getSubtopics() {
    const value = this.getValueForAttribute('subtopics');
    return value;
  }

  // -- Relationship Fields: subtopics --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionRelationshipGetters(rel));
    } else {
      return this.__withQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSearchQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSearchQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'search_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSearchQuestionRelationshipGetters(rel));
    } else {
      return this.__withSearchQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withPublicQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getPublicQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'public_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withPublicQuestionRelationshipGetters(rel));
    } else {
      return this.__withPublicQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyGuideLevelModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'description': null,
  'free': null,
  'id': null,
  'inserted_at': null,
  'level': null,
  'meta.correctly_answered_in_guide': null,
  'meta.total_answered_in_guide': null,
  'name': null,
  'nlevel': null,
  'position': null,
  'summary': null,
  'theme_img': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free': new Set(['exact','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'level': new Set(['exact','not','null']),
  'meta.correctly_answered_in_guide': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.total_answered_in_guide': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'nlevel': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'public_questions_v1': new Set(['associated']),
  'question_sets_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'subtopics_v1': new Set(['associated']),
  'summary': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'tags_v1': new Set(['associated']),
  'theme_img': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'topics_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'student_id': 'meta.context.student.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const GuideLevel = GuideLevelModelV1;
