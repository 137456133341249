/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'question_sets_v1',
    'attributes': {
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'subject_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'slug_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'difficulty': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'category': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'short_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'tags_v1': {
        'type': 'one-to-many'
      },
      'templates_v1': {
        'type': 'one-to-many'
      },
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'exams_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'sections_v1': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'draft_assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v2': {
        'type': 'one-to-many'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'assignments_v3': {
        'type': 'one-to-many'
      },
      'student_assignments_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'student_assignments': Empty,
    'authoring_subject': Empty,
    'authoring_questions': Empty,
    'assignments': Empty,
    'draft_assignments': Empty,
    'name': Empty,
    'authoring_exams': Empty,
    'subject': Empty,
    'questions': Empty,
    'subject_id': Empty,
    'sections': Empty,
    'slug_id': Empty,
    'search_tags': Empty,
    'guide_levels': Empty,
    'exams': Empty,
    'search_questions': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'tags': Empty,
    'templates': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'id': Empty,
    'difficulty': Empty,
    'relationshipMeta': Empty,
    'category': Empty,
    'short_id': Empty,
    'meta': new Map({
    'is_free': Empty,
    'populated_metadata_fields': Empty,
    'guide_level_position': Empty,
    'matches_keyword_search': Empty,
    'student_question_set_answered': Empty,
    'question_difficulty_counts': Empty,
    'default_question_list_position': Empty,
    'question_type_counts': Empty,
    'student_count_of_guesses': Empty,
    'is_used_in_assignment': Empty,
    'assignment_position': Empty,
    'is_used_in_folder': Empty,
    'matches_tag_name': Empty,
    'matches_exact_tag_name': Empty,
    'matches_label_search': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'name': '',
    'subject_id': '',
    'slug_id': '',
    'id': '',
    'category': '',
    'short_id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'student_assignments': new List(),
    'authoring_questions': new List(),
    'assignments': new List(),
    'draft_assignments': new List(),
    'authoring_exams': new List(),
    'questions': new List(),
    'sections': new List(),
    'search_tags': new List(),
    'guide_levels': new List(),
    'exams': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'tags': new List(),
    'templates': new List(),
    'authoring_guide_levels': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'subject': new Map(),
    'difficulty': 0,
    'relationshipMeta': new Map()
  }
);

export class QuestionSetModelV1 extends GenericModel(ModelRecord, ModelConfig, 'QuestionSetModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyQuestionSetModelV1Model = _emptyQuestionSetModelV1Model ? _emptyQuestionSetModelV1Model : new QuestionSetModelV1(DefaultModel);
    return _emptyQuestionSetModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'QuestionSetModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'question_set_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getCategory() {
    const value = this.getValueForAttribute('category');
    return value;
  }

  setCategory(value) {
    return this.setField('category', value);
  }

  /**
   * @type {integer}
   */
  getDifficulty() {
    const value = this.getValueForAttribute('difficulty');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setDifficulty(value) {
    return this.setField('difficulty', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {string}
   */
  getShortId() {
    const value = this.getValueForAttribute('short_id');
    return value;
  }

  setShortId(value) {
    return this.setField('short_id', value);
  }

  /**
   * @type {string}
   */
  getSlugId() {
    const value = this.getValueForAttribute('slug_id');
    return value;
  }

  setSlugId(value) {
    return this.setField('slug_id', value);
  }

  /**
   * @type {binary_id}
   */
  getSubjectId() {
    const value = this.getValueForAttribute('subject_id');
    return value;
  }

  setSubjectId(value) {
    return this.setField('subject_id', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAssignmentPosition() {
        return this.getMeta().getAssignmentPosition();
      }
    
      getDefaultQuestionListPosition() {
        return this.getMeta().getDefaultQuestionListPosition();
      }
    
      getGuideLevelPosition() {
        return this.getMeta().getGuideLevelPosition();
      }
    
      isFree() {
        return this.getMeta().isFree();
      }
    
      isUsedInAssignment() {
        return this.getMeta().isUsedInAssignment();
      }
    
      isUsedInFolder() {
        return this.getMeta().isUsedInFolder();
      }
    
      isMatchesExactTagName() {
        return this.getMeta().isMatchesExactTagName();
      }
    
      isMatchesKeywordSearch() {
        return this.getMeta().isMatchesKeywordSearch();
      }
    
      isMatchesLabelSearch() {
        return this.getMeta().isMatchesLabelSearch();
      }
    
      isMatchesTagName() {
        return this.getMeta().isMatchesTagName();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionDifficultyCounts() {
        return this.getMeta().getQuestionDifficultyCounts();
      }
    
      getQuestionTypeCounts() {
        return this.getMeta().getQuestionTypeCounts();
      }
    
      getStudentCountOfGuesses() {
        return this.getMeta().getStudentCountOfGuesses();
      }
    
      isStudentQuestionSetAnswered() {
        return this.getMeta().isStudentQuestionSetAnswered();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getAssignmentPosition() {
            const value = meta.get('assignment_position');
            validate(value, 'assignment_position');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getDefaultQuestionListPosition() {
            const value = meta.get('default_question_list_position');
            validate(value, 'default_question_list_position');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getGuideLevelPosition() {
            const value = meta.get('guide_level_position');
            validate(value, 'guide_level_position');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isFree() {
            const value = meta.get('is_free');
            validate(value, 'is_free');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isUsedInAssignment() {
            const value = meta.get('is_used_in_assignment');
            validate(value, 'is_used_in_assignment');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isUsedInFolder() {
            const value = meta.get('is_used_in_folder');
            validate(value, 'is_used_in_folder');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesExactTagName() {
            const value = meta.get('matches_exact_tag_name');
            validate(value, 'matches_exact_tag_name');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesKeywordSearch() {
            const value = meta.get('matches_keyword_search');
            validate(value, 'matches_keyword_search');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesLabelSearch() {
            const value = meta.get('matches_label_search');
            validate(value, 'matches_label_search');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesTagName() {
            const value = meta.get('matches_tag_name');
            validate(value, 'matches_tag_name');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map}
           */
          getQuestionDifficultyCounts() {
            const value = meta.get('question_difficulty_counts');
            validate(value, 'question_difficulty_counts');
            return value;
          },
  
          /**
           * @type {map}
           */
          getQuestionTypeCounts() {
            const value = meta.get('question_type_counts');
            validate(value, 'question_type_counts');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfGuesses() {
            const value = meta.get('student_count_of_guesses');
            validate(value, 'student_count_of_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isStudentQuestionSetAnswered() {
            const value = meta.get('student_question_set_answered');
            validate(value, 'student_question_set_answered');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {student_assignment_v1 (many)}
   */
  getStudentAssignments() {
    const value = this.getValueForAttribute('student_assignments');
    return value;
  }

  // -- Relationship Fields: student_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentAssignmentRelationshipGetters(rel));
    } else {
      return this.__withStudentAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {assignment_v3 (many)}
   */
  getAssignments() {
    const value = this.getValueForAttribute('assignments');
    return value;
  }

  // -- Relationship Fields: assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAssignmentRelationshipGetters(rel));
    } else {
      return this.__withAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {draft_assignment_v1 (many)}
   */
  getDraftAssignments() {
    const value = this.getValueForAttribute('draft_assignments');
    return value;
  }

  // -- Relationship Fields: draft_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withDraftAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getDraftAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'draft_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withDraftAssignmentRelationshipGetters(rel));
    } else {
      return this.__withDraftAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringExamRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringExamRelationshipGetters(rel));
    } else {
      return this.__withAuthoringExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {section_v1 (many)}
   */
  getSections() {
    const value = this.getValueForAttribute('sections');
    return value;
  }

  // -- Relationship Fields: sections --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSectionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSectionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'section'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSectionRelationshipGetters(rel));
    } else {
      return this.__withSectionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {exam_v1 (many)}
   */
  getExams() {
    const value = this.getValueForAttribute('exams');
    return value;
  }

  // -- Relationship Fields: exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withExamRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withExamRelationshipGetters(rel));
    } else {
      return this.__withExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {template_v1 (many)}
   */
  getTemplates() {
    const value = this.getValueForAttribute('templates');
    return value;
  }

  // -- Relationship Fields: templates --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTemplateRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTemplateRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'template'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTemplateRelationshipGetters(rel));
    } else {
      return this.__withTemplateRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withAuthoringGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

}

// -- Empty Model Instance --
let _emptyQuestionSetModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'category': null,
  'difficulty': null,
  'id': null,
  'inserted_at': null,
  'meta.assignment_position': null,
  'meta.default_question_list_position': null,
  'meta.guide_level_position': null,
  'meta.is_free': null,
  'meta.is_used_in_assignment': null,
  'meta.is_used_in_folder': null,
  'meta.matches_exact_tag_name': null,
  'meta.matches_keyword_search': null,
  'meta.matches_label_search': null,
  'meta.matches_tag_name': null,
  'meta.student_count_of_guesses': null,
  'meta.student_question_set_answered': null,
  'name': null,
  'short_id': null,
  'slug_id': null,
  'subject_id': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignments_v1': new Set(['associated']),
  'assignments_v2': new Set(['associated']),
  'assignments_v3': new Set(['associated']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'category': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'difficulty': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'draft_assignments_v1': new Set(['associated']),
  'exams_v1': new Set(['associated']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.default_question_list_position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.guide_level_position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.is_free': new Set(['exact','not','null']),
  'meta.is_used_in_assignment': new Set(['exact','not','null']),
  'meta.is_used_in_folder': new Set(['exact','not','null']),
  'meta.matches_exact_tag_name': new Set(['exact','not','null']),
  'meta.matches_keyword_search': new Set(['exact','not','null']),
  'meta.matches_label_search': new Set(['exact','not','null']),
  'meta.matches_tag_name': new Set(['exact','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.student_count_of_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_question_set_answered': new Set(['exact','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'public_questions_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'sections_v1': new Set(['associated']),
  'short_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'slug_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'student_assignments_v1': new Set(['associated']),
  'subject_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'tags_v1': new Set(['associated']),
  'templates_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_id': 'meta.context.assignment.id',
    'assignment_usage': 'meta.context.assignment_usage',
    'blocked_assignment_question': 'meta.context.blocked_assignment_question',
    'default_question_list_position': 'meta.context.default_question_list_position',
    'draft_assignment_id': 'meta.context.draft_assignment.id',
    'folder_usage': 'meta.context.folder_usage',
    'guide_level_id': 'meta.context.guide_level.id',
    'no_user': 'meta.context.no_user',
    'question_difficulty_counts': 'meta.context.question_difficulty_counts',
    'question_type_counts': 'meta.context.question_type_counts',
    'keyword_search': 'meta.context.search.keyword',
    'label_search': 'meta.context.search.label',
    'search_questions_index': 'meta.context.search.questions.index',
    'tag_name_exact_search': 'meta.context.search.tag_name_exact_match',
    'tag_name_search': 'meta.context.search.tag_name_match',
    'student_id': 'meta.context.student.id',
    'template_id': 'meta.context.template.id',
    'user_id': 'meta.context.user.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const QuestionSet = QuestionSetModelV1;
