/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'subjects_v1',
    'attributes': {
      'featured_as': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'domain': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'hidden': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'code': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'alternate_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'footer_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free_response_questions_published': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'logo_image_url': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'group': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'subscriptions_v1': {
        'type': 'one-to-many'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'curriculum_area_v1': {
        'type': 'one-to-one'
      },
      'subject_faqs_v1': {
        'type': 'one-to-many'
      },
      'school_license_subject_groups_v1': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'themes_v1': {
        'type': 'one-to-many'
      },
      'subtopics_v1': {
        'type': 'one-to-many'
      },
      'topics_v1': {
        'type': 'one-to-many'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      },
      'school_licenses_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'authoring_question_sets': Empty,
    'featured_as': Empty,
    'domain': Empty,
    'student_classrooms': Empty,
    'topics': Empty,
    'subtopics': Empty,
    'question_sets': Empty,
    'hidden': Empty,
    'themes': Empty,
    'name': Empty,
    'code': Empty,
    'school_license_subject_groups': Empty,
    'alternate_name': Empty,
    'subject_faqs': Empty,
    'footer_text': Empty,
    'free_response_questions_published': Empty,
    'curriculum_area': Empty,
    'guide_levels': Empty,
    'authoring_supplements': Empty,
    'subscriptions': Empty,
    'updated_at': Empty,
    'logo_image_url': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'supplements': Empty,
    'id': Empty,
    'url_slug': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'school_licenses': Empty,
    'group': Empty,
    'meta': new Map({
    'count_of_difficult_questions_answered_correctly': Empty,
    'count_of_medium_questions_answered': Empty,
    'sum_of_time_elapsed_on_medium_questions': Empty,
    'sum_of_points_earned_on_medium_questions': Empty,
    'populated_metadata_fields': Empty,
    'count_of_difficult_questions_answered': Empty,
    'sum_of_points_earned_on_difficult_questions': Empty,
    'count_of_easy_questions_answered_correctly': Empty,
    'sum_of_time_elapsed_on_difficult_questions': Empty,
    'sum_of_time_elapsed_on_easy_questions': Empty,
    'sum_of_points_earned_on_easy_questions': Empty,
    'count_of_easy_questions_answered': Empty,
    'count_of_medium_questions_answered_correctly': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'featured_as': '',
    'domain': '',
    'name': '',
    'code': '',
    'alternate_name': '',
    'footer_text': '',
    'logo_image_url': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'group': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'authoring_question_sets': new List(),
    'student_classrooms': new List(),
    'topics': new List(),
    'subtopics': new List(),
    'question_sets': new List(),
    'themes': new List(),
    'meta': new Map(),
    'hidden': false,
    'school_license_subject_groups': new List(),
    'subject_faqs': new List(),
    'free_response_questions_published': false,
    'guide_levels': new List(),
    'authoring_supplements': new List(),
    'subscriptions': new List(),
    'authoring_guide_levels': new List(),
    'supplements': new List(),
    'school_licenses': new List(),
    'curriculum_area': new Map(),
    'relationshipMeta': new Map()
  }
);

export class SubjectModelV1 extends GenericModel(ModelRecord, ModelConfig, 'SubjectModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySubjectModelV1Model = _emptySubjectModelV1Model ? _emptySubjectModelV1Model : new SubjectModelV1(DefaultModel);
    return _emptySubjectModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SubjectModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'subject_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getAlternateName() {
    const value = this.getValueForAttribute('alternate_name');
    return value;
  }

  setAlternateName(value) {
    return this.setField('alternate_name', value);
  }

  /**
   * @type {string}
   */
  getCode() {
    const value = this.getValueForAttribute('code');
    return value;
  }

  setCode(value) {
    return this.setField('code', value);
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {string}
   */
  getDomain() {
    const value = this.getValueForAttribute('domain');
    return value;
  }

  setDomain(value) {
    return this.setField('domain', value);
  }

  /**
   * @type {string}
   */
  getFeaturedAs() {
    const value = this.getValueForAttribute('featured_as');
    return value;
  }

  setFeaturedAs(value) {
    return this.setField('featured_as', value);
  }

  /**
   * @type {string}
   */
  getFooterText() {
    const value = this.getValueForAttribute('footer_text');
    return value;
  }

  setFooterText(value) {
    return this.setField('footer_text', value);
  }

  /**
   * @type {boolean}
   */
  isFreeResponseQuestionsPublished() {
    const value = this.getValueForAttribute('free_response_questions_published');
    return value;
  }

  setFreeResponseQuestionsPublished(value) {
    return this.setField('free_response_questions_published', value);
  }

  /**
   * @type {string}
   */
  getGroup() {
    const value = this.getValueForAttribute('group');
    return value;
  }

  setGroup(value) {
    return this.setField('group', value);
  }

  /**
   * @type {boolean}
   */
  isHidden() {
    const value = this.getValueForAttribute('hidden');
    return value;
  }

  setHidden(value) {
    return this.setField('hidden', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLogoImageUrl() {
    const value = this.getValueForAttribute('logo_image_url');
    return value;
  }

  setLogoImageUrl(value) {
    return this.setField('logo_image_url', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getCountOfDifficultQuestionsAnswered() {
        return this.getMeta().getCountOfDifficultQuestionsAnswered();
      }
    
      getCountOfDifficultQuestionsAnsweredCorrectly() {
        return this.getMeta().getCountOfDifficultQuestionsAnsweredCorrectly();
      }
    
      getCountOfEasyQuestionsAnswered() {
        return this.getMeta().getCountOfEasyQuestionsAnswered();
      }
    
      getCountOfEasyQuestionsAnsweredCorrectly() {
        return this.getMeta().getCountOfEasyQuestionsAnsweredCorrectly();
      }
    
      getCountOfMediumQuestionsAnswered() {
        return this.getMeta().getCountOfMediumQuestionsAnswered();
      }
    
      getCountOfMediumQuestionsAnsweredCorrectly() {
        return this.getMeta().getCountOfMediumQuestionsAnsweredCorrectly();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSumOfPointsEarnedOnDifficultQuestions() {
        return this.getMeta().getSumOfPointsEarnedOnDifficultQuestions();
      }
    
      getSumOfPointsEarnedOnEasyQuestions() {
        return this.getMeta().getSumOfPointsEarnedOnEasyQuestions();
      }
    
      getSumOfPointsEarnedOnMediumQuestions() {
        return this.getMeta().getSumOfPointsEarnedOnMediumQuestions();
      }
    
      getSumOfTimeElapsedOnDifficultQuestions() {
        return this.getMeta().getSumOfTimeElapsedOnDifficultQuestions();
      }
    
      getSumOfTimeElapsedOnEasyQuestions() {
        return this.getMeta().getSumOfTimeElapsedOnEasyQuestions();
      }
    
      getSumOfTimeElapsedOnMediumQuestions() {
        return this.getMeta().getSumOfTimeElapsedOnMediumQuestions();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getCountOfDifficultQuestionsAnswered() {
            const value = meta.get('count_of_difficult_questions_answered');
            validate(value, 'count_of_difficult_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultQuestionsAnsweredCorrectly() {
            const value = meta.get('count_of_difficult_questions_answered_correctly');
            validate(value, 'count_of_difficult_questions_answered_correctly');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyQuestionsAnswered() {
            const value = meta.get('count_of_easy_questions_answered');
            validate(value, 'count_of_easy_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyQuestionsAnsweredCorrectly() {
            const value = meta.get('count_of_easy_questions_answered_correctly');
            validate(value, 'count_of_easy_questions_answered_correctly');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumQuestionsAnswered() {
            const value = meta.get('count_of_medium_questions_answered');
            validate(value, 'count_of_medium_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumQuestionsAnsweredCorrectly() {
            const value = meta.get('count_of_medium_questions_answered_correctly');
            validate(value, 'count_of_medium_questions_answered_correctly');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getSumOfPointsEarnedOnDifficultQuestions() {
            const value = meta.get('sum_of_points_earned_on_difficult_questions');
            validate(value, 'sum_of_points_earned_on_difficult_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfPointsEarnedOnEasyQuestions() {
            const value = meta.get('sum_of_points_earned_on_easy_questions');
            validate(value, 'sum_of_points_earned_on_easy_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfPointsEarnedOnMediumQuestions() {
            const value = meta.get('sum_of_points_earned_on_medium_questions');
            validate(value, 'sum_of_points_earned_on_medium_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfTimeElapsedOnDifficultQuestions() {
            const value = meta.get('sum_of_time_elapsed_on_difficult_questions');
            validate(value, 'sum_of_time_elapsed_on_difficult_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfTimeElapsedOnEasyQuestions() {
            const value = meta.get('sum_of_time_elapsed_on_easy_questions');
            validate(value, 'sum_of_time_elapsed_on_easy_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfTimeElapsedOnMediumQuestions() {
            const value = meta.get('sum_of_time_elapsed_on_medium_questions');
            validate(value, 'sum_of_time_elapsed_on_medium_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withClassroomRelationshipGetters(rel));
    } else {
      return this.__withClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentClassroomRelationshipGetters(rel));
    } else {
      return this.__withStudentClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {guide_level_v1 (many)}
   */
  getTopics() {
    const value = this.getValueForAttribute('topics');
    return value;
  }

  // -- Relationship Fields: topics --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v1 (many)}
   */
  getSubtopics() {
    const value = this.getValueForAttribute('subtopics');
    return value;
  }

  // -- Relationship Fields: subtopics --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v1 (many)}
   */
  getThemes() {
    const value = this.getValueForAttribute('themes');
    return value;
  }

  // -- Relationship Fields: themes --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_subject_group_v1 (many)}
   */
  getSchoolLicenseSubjectGroups() {
    const value = this.getValueForAttribute('school_license_subject_groups');
    return value;
  }

  // -- Relationship Fields: school_license_subject_groups --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_faq_v1 (many)}
   */
  getSubjectFaqs() {
    const value = this.getValueForAttribute('subject_faqs');
    return value;
  }

  // -- Relationship Fields: subject_faqs --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {curriculum_area_v1 (one)}
   */
  getCurriculumArea() {
    const value = this.getValueForAttribute('curriculum_area');
    return value;
  }

  // -- Relationship Fields: curriculum_area --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subscription_v1 (many)}
   */
  getSubscriptions() {
    const value = this.getValueForAttribute('subscriptions');
    return value;
  }

  // -- Relationship Fields: subscriptions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_v1 (many)}
   */
  getSchoolLicenses() {
    const value = this.getValueForAttribute('school_licenses');
    return value;
  }

  // -- Relationship Fields: school_licenses --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySubjectModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'alternate_name': null,
  'code': null,
  'description': null,
  'domain': null,
  'featured_as': null,
  'footer_text': null,
  'free_response_questions_published': null,
  'group': null,
  'hidden': null,
  'id': null,
  'inserted_at': null,
  'logo_image_url': null,
  'meta.count_of_difficult_questions_answered': null,
  'meta.count_of_difficult_questions_answered_correctly': null,
  'meta.count_of_easy_questions_answered': null,
  'meta.count_of_easy_questions_answered_correctly': null,
  'meta.count_of_medium_questions_answered': null,
  'meta.count_of_medium_questions_answered_correctly': null,
  'meta.sum_of_points_earned_on_difficult_questions': null,
  'meta.sum_of_points_earned_on_easy_questions': null,
  'meta.sum_of_points_earned_on_medium_questions': null,
  'meta.sum_of_time_elapsed_on_difficult_questions': null,
  'meta.sum_of_time_elapsed_on_easy_questions': null,
  'meta.sum_of_time_elapsed_on_medium_questions': null,
  'name': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'alternate_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'classrooms_v1': new Set(['associated']),
  'code': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'curriculum_area_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'domain': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'featured_as': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'footer_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free_response_questions_published': new Set(['exact','not','null']),
  'group': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'hidden': new Set(['exact','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'logo_image_url': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.count_of_difficult_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_difficult_questions_answered_correctly': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_questions_answered_correctly': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_questions_answered_correctly': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.sum_of_points_earned_on_difficult_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_points_earned_on_easy_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_points_earned_on_medium_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_time_elapsed_on_difficult_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_time_elapsed_on_easy_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_time_elapsed_on_medium_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_sets_v1': new Set(['associated']),
  'school_license_subject_groups_v1': new Set(['associated']),
  'school_licenses_v1': new Set(['associated']),
  'student_classrooms_v1': new Set(['associated']),
  'subject_faqs_v1': new Set(['associated']),
  'subscriptions_v1': new Set(['associated']),
  'subtopics_v1': new Set(['associated']),
  'supplements_v1': new Set(['associated']),
  'themes_v1': new Set(['associated']),
  'topics_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'student_id': 'meta.context.student.id',
    'subject_breakdown': 'meta.context.subject.breakdown',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Subject = SubjectModelV1;
