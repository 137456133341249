/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'sections_v1',
    'attributes': {
      'position': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'instructions': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'time_limit': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'title': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'template_v1': {
        'type': 'one-to-one'
      },
      'students_v1': {
        'type': 'one-to-many'
      },
      'students_v2': {
        'type': 'one-to-many'
      },
      'assignment_v3': {
        'type': 'one-to-one'
      },
      'draft_assignment_v1': {
        'type': 'one-to-one'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'authoring_exam_v1': {
        'type': 'one-to-one'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'exam_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_question_sets': Empty,
    'instructions': Empty,
    'authoring_exam': Empty,
    'position': Empty,
    'time_limit': Empty,
    'question_sets': Empty,
    'draft_assignment': Empty,
    'assignment': Empty,
    'updated_at': Empty,
    'students': Empty,
    'title': Empty,
    'template': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'exam': Empty,
    'meta': new Map({
    'count_of_questions': Empty,
    'populated_metadata_fields': Empty,
    'student_count_of_correct_guesses': Empty,
    'student_count_of_guesses': Empty,
    'student_submitted': Empty,
    'student_submitted_at': Empty,
    'student_time_spent': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'instructions': '',
    'title': '',
    'id': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_question_sets': new List(),
    'question_sets': new List(),
    'students': new List(),
    'meta': new Map(),
    'authoring_exam': new Map(),
    'position': 0,
    'time_limit': 0,
    'draft_assignment': new Map(),
    'assignment': new Map(),
    'template': new Map(),
    'relationshipMeta': new Map(),
    'exam': new Map()
  }
);

export class SectionModelV1 extends GenericModel(ModelRecord, ModelConfig, 'SectionModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySectionModelV1Model = _emptySectionModelV1Model ? _emptySectionModelV1Model : new SectionModelV1(DefaultModel);
    return _emptySectionModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SectionModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'section_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getInstructions() {
    const value = this.getValueForAttribute('instructions');
    return value;
  }

  setInstructions(value) {
    return this.setField('instructions', value);
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPosition(value) {
    return this.setField('position', value);
  }

  /**
   * @type {integer}
   */
  getTimeLimit() {
    const value = this.getValueForAttribute('time_limit');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setTimeLimit(value) {
    return this.setField('time_limit', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getStudentCountOfCorrectGuesses() {
        return this.getMeta().getStudentCountOfCorrectGuesses();
      }
    
      getStudentCountOfGuesses() {
        return this.getMeta().getStudentCountOfGuesses();
      }
    
      isStudentSubmitted() {
        return this.getMeta().isStudentSubmitted();
      }
    
      getStudentSubmittedAt() {
        return this.getMeta().getStudentSubmittedAt();
      }
    
      getStudentTimeSpent() {
        return this.getMeta().getStudentTimeSpent();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfCorrectGuesses() {
            const value = meta.get('student_count_of_correct_guesses');
            validate(value, 'student_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfGuesses() {
            const value = meta.get('student_count_of_guesses');
            validate(value, 'student_count_of_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isStudentSubmitted() {
            const value = meta.get('student_submitted');
            validate(value, 'student_submitted');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getStudentSubmittedAt() {
            const value = meta.get('student_submitted_at');
            validate(value, 'student_submitted_at');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentTimeSpent() {
            const value = meta.get('student_time_spent');
            validate(value, 'student_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {assignment_v3 (one)}
   */
  getAssignment() {
    const value = this.getValueForAttribute('assignment');
    return value;
  }

  // -- Relationship Fields: assignment --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_exam_v1 (one)}
   */
  getAuthoringExam() {
    const value = this.getValueForAttribute('authoring_exam');
    return value;
  }

  // -- Relationship Fields: authoring_exam --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {draft_assignment_v1 (one)}
   */
  getDraftAssignment() {
    const value = this.getValueForAttribute('draft_assignment');
    return value;
  }

  // -- Relationship Fields: draft_assignment --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {exam_v1 (one)}
   */
  getExam() {
    const value = this.getValueForAttribute('exam');
    return value;
  }

  // -- Relationship Fields: exam --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {student_v2 (many)}
   */
  getStudents() {
    const value = this.getValueForAttribute('students');
    return value;
  }

  // -- Relationship Fields: students --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmittedAt() {
        return data.get('submitted_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentRelationshipGetters(rel));
    } else {
      return this.__withStudentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {template_v1 (one)}
   */
  getTemplate() {
    const value = this.getValueForAttribute('template');
    return value;
  }

  // -- Relationship Fields: template --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySectionModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'description': null,
  'id': null,
  'inserted_at': null,
  'instructions': null,
  'meta.count_of_questions': null,
  'meta.student_count_of_correct_guesses': null,
  'meta.student_count_of_guesses': null,
  'meta.student_submitted': null,
  'meta.student_submitted_at': null,
  'meta.student_time_spent': null,
  'position': null,
  'time_limit': null,
  'title': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignment_v3': new Set(['associated']),
  'authoring_exam_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'draft_assignment_v1': new Set(['associated']),
  'exam_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'instructions': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.student_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_count_of_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_submitted': new Set(['exact','not','null']),
  'meta.student_submitted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'question_sets_v1': new Set(['associated']),
  'students_v1': new Set(['associated']),
  'students_v2': new Set(['associated']),
  'template_v1': new Set(['associated']),
  'time_limit': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'student_id': 'meta.context.student.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Section = SectionModelV1;
