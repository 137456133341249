/* eslint-disable */


// Programatically generated on Thu Apr 03 2025 16:07:14 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'guide_levels_v2',
    'attributes': {
      'level': {
        'type': 'ltree',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'summary': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'position': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'theme_img': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'parent_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'nlevel': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'tags': {
        'type': 'string[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'tags_v1': {
        'type': 'one-to-many'
      },
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'authoring_guide_v1': {
        'type': 'one-to-one'
      },
      'authoring_parent_v1': {
        'type': 'one-to-one'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'parent_v1': {
        'type': 'one-to-one'
      },
      'parent_v2': {
        'type': 'one-to-one'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'guide_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_question_sets': Empty,
    'authoring_subject': Empty,
    'level': Empty,
    'authoring_questions': Empty,
    'summary': Empty,
    'position': Empty,
    'parent': Empty,
    'question_sets': Empty,
    'name': Empty,
    'subject': Empty,
    'free': Empty,
    'theme_img': Empty,
    'questions': Empty,
    'search_tags': Empty,
    'parent_id': Empty,
    'authoring_parent': Empty,
    'authoring_guide': Empty,
    'search_questions': Empty,
    'nlevel': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'tags': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'url_slug': Empty,
    'guide': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'total_matching_questions': Empty,
    'smart_select_results': Empty,
    'student_sum_of_guesses_points_earned': Empty,
    'student_count_of_unique_guesses': Empty,
    'content_discovery_question_count': Empty,
    'student_count_of_guesses': Empty,
    'matches_search': Empty,
    'student_sum_of_guesses_time_elapsed': Empty,
    'count_of_questions': Empty,
    'student_max_attempt_number': Empty,
    'min_size_question_set': Empty,
    'student_count_of_correct_guesses': Empty,
    'student_accuracy': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'level': '',
    'summary': '',
    'name': '',
    'theme_img': '',
    'parent_id': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_question_sets': new List(),
    'authoring_questions': new List(),
    'question_sets': new List(),
    'questions': new List(),
    'search_tags': new List(),
    'tags': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'position': 0,
    'search_questions': new List(),
    'public_questions': new List(),
    'parent': new Map(),
    'subject': new Map(),
    'free': false,
    'authoring_parent': new Map(),
    'authoring_guide': new Map(),
    'nlevel': 0,
    'guide': new Map(),
    'relationshipMeta': new Map()
  }
);

export class GuideLevelModelV2 extends GenericModel(ModelRecord, ModelConfig, 'GuideLevelModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyGuideLevelModelV2Model = _emptyGuideLevelModelV2Model ? _emptyGuideLevelModelV2Model : new GuideLevelModelV2(DefaultModel);
    return _emptyGuideLevelModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'GuideLevelModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'guide_level_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {boolean}
   */
  isFree() {
    const value = this.getValueForAttribute('free');
    return value;
  }

  setFree(value) {
    return this.setField('free', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {ltree}
   */
  getLevel() {
    const value = this.getValueForAttribute('level');
    return value;
  }

  setLevel(value) {
    return this.setField('level', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {integer}
   */
  getNlevel() {
    const value = this.getValueForAttribute('nlevel');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setNlevel(value) {
    return this.setField('nlevel', value);
  }

  /**
   * @type {binary_id}
   */
  getParentId() {
    const value = this.getValueForAttribute('parent_id');
    return value;
  }

  setParentId(value) {
    return this.setField('parent_id', value);
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPosition(value) {
    return this.setField('position', value);
  }

  /**
   * @type {string}
   */
  getSummary() {
    const value = this.getValueForAttribute('summary');
    return value;
  }

  setSummary(value) {
    return this.setField('summary', value);
  }

  /**
   * @type {string[]}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  setTags(value) {
    return this.setField('tags', value);
  }

  /**
   * @type {string}
   */
  getThemeImg() {
    const value = this.getValueForAttribute('theme_img');
    return value;
  }

  setThemeImg(value) {
    return this.setField('theme_img', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getContentDiscoveryQuestionCount() {
        return this.getMeta().getContentDiscoveryQuestionCount();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      isMatchesSearch() {
        return this.getMeta().isMatchesSearch();
      }
    
      getMinSizeQuestionSet() {
        return this.getMeta().getMinSizeQuestionSet();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSmartSelectResults() {
        return this.getMeta().getSmartSelectResults();
      }
    
      getStudentAccuracy() {
        return this.getMeta().getStudentAccuracy();
      }
    
      getStudentCountOfCorrectGuesses() {
        return this.getMeta().getStudentCountOfCorrectGuesses();
      }
    
      getStudentCountOfGuesses() {
        return this.getMeta().getStudentCountOfGuesses();
      }
    
      getStudentCountOfUniqueGuesses() {
        return this.getMeta().getStudentCountOfUniqueGuesses();
      }
    
      getStudentMaxAttemptNumber() {
        return this.getMeta().getStudentMaxAttemptNumber();
      }
    
      getStudentSumOfGuessesPointsEarned() {
        return this.getMeta().getStudentSumOfGuessesPointsEarned();
      }
    
      getStudentSumOfGuessesTimeElapsed() {
        return this.getMeta().getStudentSumOfGuessesTimeElapsed();
      }
    
      getTotalMatchingQuestions() {
        return this.getMeta().getTotalMatchingQuestions();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getContentDiscoveryQuestionCount() {
            const value = meta.get('content_discovery_question_count');
            validate(value, 'content_discovery_question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isMatchesSearch() {
            const value = meta.get('matches_search');
            validate(value, 'matches_search');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getMinSizeQuestionSet() {
            const value = meta.get('min_size_question_set');
            validate(value, 'min_size_question_set');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map[]}
           */
          getSmartSelectResults() {
            const value = meta.get('smart_select_results');
            validate(value, 'smart_select_results');
            return value;
          },
  
          /**
           * @type {float}
           */
          getStudentAccuracy() {
            const value = meta.get('student_accuracy');
            validate(value, 'student_accuracy');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfCorrectGuesses() {
            const value = meta.get('student_count_of_correct_guesses');
            validate(value, 'student_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfGuesses() {
            const value = meta.get('student_count_of_guesses');
            validate(value, 'student_count_of_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfUniqueGuesses() {
            const value = meta.get('student_count_of_unique_guesses');
            validate(value, 'student_count_of_unique_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentMaxAttemptNumber() {
            const value = meta.get('student_max_attempt_number');
            validate(value, 'student_max_attempt_number');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentSumOfGuessesPointsEarned() {
            const value = meta.get('student_sum_of_guesses_points_earned');
            validate(value, 'student_sum_of_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentSumOfGuessesTimeElapsed() {
            const value = meta.get('student_sum_of_guesses_time_elapsed');
            validate(value, 'student_sum_of_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getTotalMatchingQuestions() {
            const value = meta.get('total_matching_questions');
            validate(value, 'total_matching_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {guide_level_v2 (one)}
   */
  getParent() {
    const value = this.getValueForAttribute('parent');
    return value;
  }

  // -- Relationship Fields: parent --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionRelationshipGetters(rel));
    } else {
      return this.__withQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (one)}
   */
  getAuthoringParent() {
    const value = this.getValueForAttribute('authoring_parent');
    return value;
  }

  // -- Relationship Fields: authoring_parent --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_v1 (one)}
   */
  getAuthoringGuide() {
    const value = this.getValueForAttribute('authoring_guide');
    return value;
  }

  // -- Relationship Fields: authoring_guide --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSearchQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSearchQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'search_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSearchQuestionRelationshipGetters(rel));
    } else {
      return this.__withSearchQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withPublicQuestionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getPublicQuestionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'public_question'], new Map());
    if (!id) {
      return data.map((rel) => this.__withPublicQuestionRelationshipGetters(rel));
    } else {
      return this.__withPublicQuestionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_v1 (one)}
   */
  getGuide() {
    const value = this.getValueForAttribute('guide');
    return value;
  }

  // -- Relationship Fields: guide --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyGuideLevelModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'description': null,
  'free': null,
  'id': null,
  'inserted_at': null,
  'level': null,
  'meta.content_discovery_question_count': null,
  'meta.count_of_questions': null,
  'meta.matches_search': null,
  'meta.min_size_question_set': null,
  'meta.student_accuracy': null,
  'meta.student_count_of_correct_guesses': null,
  'meta.student_count_of_guesses': null,
  'meta.student_count_of_unique_guesses': null,
  'meta.student_max_attempt_number': null,
  'meta.student_sum_of_guesses_points_earned': null,
  'meta.student_sum_of_guesses_time_elapsed': null,
  'meta.total_matching_questions': null,
  'name': null,
  'nlevel': null,
  'parent_id': null,
  'position': null,
  'summary': null,
  'theme_img': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_guide_v1': new Set(['associated']),
  'authoring_parent_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free': new Set(['exact','not','null']),
  'guide_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'level': new Set(['exact','not','null']),
  'meta.content_discovery_question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.matches_search': new Set(['exact','not','null']),
  'meta.min_size_question_set': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.student_accuracy': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_count_of_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_count_of_unique_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_max_attempt_number': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_sum_of_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_sum_of_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.total_matching_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'nlevel': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'parent_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'parent_v1': new Set(['associated']),
  'parent_v2': new Set(['associated']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'public_questions_v1': new Set(['associated']),
  'question_sets_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'summary': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'tags': new Set(['null']),
  'tags_v1': new Set(['associated']),
  'theme_img': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'content_discovery_filtering': 'meta.context.content_discovery_filtering',
    'content_discovery_filters_hide_assessment_questions': 'meta.context.content_discovery_filters.hide_assessment_questions',
    'content_discovery_filters_hide_previously_assigned': 'meta.context.content_discovery_filters.hide_previously_assigned',
    'content_discovery_filters_question_difficulties': 'meta.context.content_discovery_filters.question_difficulties',
    'content_discovery_filters_question_types': 'meta.context.content_discovery_filters.question_types',
    'content_discovery_filters_standard_sets': 'meta.context.content_discovery_filters.standard_sets',
    'content_discovery_filters_standards': 'meta.context.content_discovery_filters.standards',
    'content_discovery_filters_subjects': 'meta.context.content_discovery_filters.subjects',
    'end_date': 'meta.context.end_date',
    'attempt_number': 'meta.context.guess.attempt_number',
    'most_recent': 'meta.context.guess.most_recent',
    'questions_have_standards': 'meta.context.questions.have_standards',
    'questions_have_tags': 'meta.context.questions.have_tags',
    'questions_match_text': 'meta.context.questions.match_text',
    'search_fields': 'meta.context.search.fields',
    'search_term': 'meta.context.search.term',
    'excluded_question_set_ids': 'meta.context.smart_select.excluded_question_set_ids',
    'expected_completion_time': 'meta.context.smart_select.expected_completion_time',
    'proportion_difficult': 'meta.context.smart_select.proportion_difficult',
    'proportion_easy': 'meta.context.smart_select.proportion_easy',
    'proportion_moderate': 'meta.context.smart_select.proportion_moderate',
    'tolerance_completion_time': 'meta.context.smart_select.tolerance_completion_time',
    'tolerance_total_questions': 'meta.context.smart_select.tolerance_total_questions',
    'total_number_of_questions': 'meta.context.smart_select.total_number_of_questions',
    'start_date': 'meta.context.start_date',
    'student_id': 'meta.context.student.id',
    'user_id': 'meta.context.user.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const GuideLevel = GuideLevelModelV2;
